import { TextSegmentDto } from '@domain/api/generated/design-api';
import {
    ITextSpan,
    IVersionProperty,
    IVersionedText,
    IWidgetText,
    OneOfVersionableProperties
} from '@domain/creativeset/version';
import { IFeed } from '@domain/feed';
import { IFontStyle } from '@domain/font';
import {
    OneOfElementDataNodes,
    OneOfElementPropertyKeys,
    OneOfTextDataNodes,
    OneOfViewNodes
} from '@domain/nodes';
import { textProperties } from '@domain/property';
import { IPadding, IShadow } from '@domain/style';
import {
    CharacterPropertyKeys,
    HorizontalAlignment,
    ICharacterProperties,
    IContentSpan,
    IRenderedSpans,
    ISpan,
    ISpanProperties,
    ISpans,
    IStyleIdMap,
    IStyleIndexMaps,
    IText,
    ITextElementProperties,
    ITextVariable,
    IVariableSpan,
    IWordSpan,
    OneOfContentSpans,
    OneOfEditableSpans,
    OneOfRenderedSpans,
    PreviousStyleIdType,
    SpanType,
    TextDirection,
    TextElementAndCharacterStyleProperties,
    VARIABLE_PREFIX,
    VerticalAlignment
} from '@domain/text';
import { IWidgetCustomProperty, IWidgetElementProperty } from '@domain/widget';
import { cloneDeep } from '@studio/utils/clone';
import { uuidv4 } from '@studio/utils/id';
import { CRC32checksum, deepEqual, mod, omit } from '@studio/utils/utils';
import { Color } from '../../color';
import { createDefaultTextProperties, isTextNode } from '../../nodes/helpers';
import { convertCharacterStyleToDto } from '../../serialization/character-properties-serializer';
import {
    deserializeVersionPropertyValue,
    serializeVersionPropertyValue
} from '../../serialization/property-value-serializer';
import { deserializeVersionedText, serializeVersionedText } from '../../serialization/text-serializer';
import { decodeFeedPath } from '../feed/feeds.utils';
import { createWordSpanFromCharacter } from './rich-text.span.utils';

export const enum CharacterCode {
    At = 0x40,
    LineFeed = 0x0a, // \n
    CarriageReturn = 0x0d, // \r
    LineSeparator = 0x2028,
    ParagraphSeparator = 0x2029,
    NextLine = 0x0085,

    // Unicode 3.0 space characters
    Space = 0x0020, // " "
    NonBreakingSpace = 0x00a0, //
    EnQuad = 0x2000,
    EmQuad = 0x2001,
    EnSpace = 0x2002,
    EmSpace = 0x2003,
    ThreePerEmSpace = 0x2004,
    FourPerEmSpace = 0x2005,
    SixPerEmSpace = 0x2006,
    FigureSpace = 0x2007,
    PunctuationSpace = 0x2008,
    ThinSpace = 0x2009,
    HairSpace = 0x200a,
    ZeroWidthSpace = 0x200b,
    NarrowNoBreakSpace = 0x202f,
    IdeographicSpace = 0x3000,
    MathematicalSpace = 0x205f,
    Ogham = 0x1680,

    ZeroWidthNonJoiner = 0x200c,
    ZeroWidthJoiner = 0x200d,

    // Other white space
    Backspace = 0x08, // \b
    FormFeed = 0x0c, // \f
    ByteOrderMark = 0xfeff,
    Tab = 0x09, // \t
    VerticalTab = 0x0b // \v
}

export function isSpaceLikeCharacter(ch: number): boolean {
    return (
        ch === CharacterCode.Space ||
        ch === CharacterCode.Tab ||
        ch === CharacterCode.VerticalTab ||
        ch === CharacterCode.FormFeed ||
        ch === CharacterCode.NextLine ||
        ch === CharacterCode.Ogham ||
        (ch >= CharacterCode.EnQuad && ch <= CharacterCode.ZeroWidthSpace) ||
        ch === CharacterCode.MathematicalSpace ||
        ch === CharacterCode.IdeographicSpace ||
        ch === CharacterCode.ByteOrderMark
    );
}

export function isWordLikeCharacter(ch: number): boolean {
    return !isSpaceLikeCharacter(ch) && !isNewlineLikeCharacter(ch);
}

export function isStyledSpan(span: OneOfEditableSpans): span is OneOfContentSpans {
    return (
        span.type === SpanType.Word ||
        span.type === SpanType.Space ||
        span.type === SpanType.Variable ||
        span.type === SpanType.Newline
    );
}

function replaceSpaceAndNewLineChars(str: string): string {
    let sanitizedString = '';
    for (let i = 0; i < str.length; i++) {
        const ch = str.charCodeAt(i);

        if (isNewlineLikeCharacter(ch)) {
            sanitizedString += '\n';
            continue;
        }

        if (isSpaceLikeCharacter(ch)) {
            sanitizedString += '\u0020';
            continue;
        }

        sanitizedString += String.fromCharCode(ch);
    }

    return sanitizedString;
}

export function sanitizeText(value: string): string {
    return replaceSpaceAndNewLineChars(value).normalize('NFC').replace(/"/gi, '"');
}

export function isVariable(
    variable: ITextVariable | IFeed,
    elementOrSpan: OneOfElementDataNodes | OneOfRenderedSpans
): variable is ITextVariable {
    if ('kind' in elementOrSpan) {
        if (isTextNode(elementOrSpan)) {
            return true;
        }
        return false;
    }

    return isVariableSpan(elementOrSpan);
}

export function isVariableSpan(span?: OneOfRenderedSpans): span is IVariableSpan {
    return span !== undefined && span.type === SpanType.Variable;
}

export function isContentSpan(span?: OneOfRenderedSpans): span is OneOfContentSpans {
    return (
        span !== undefined &&
        (span.type === SpanType.Word ||
            span.type === SpanType.Space ||
            span.type === SpanType.Newline ||
            span.type === SpanType.Variable)
    );
}

export function isWordSpan(span?: OneOfRenderedSpans): span is IWordSpan {
    return span !== undefined && span.type === SpanType.Word;
}

export function isVersionedVariableContentSpan(span: ITextSpan): boolean {
    return span.type === SpanType.Variable;
}

export function isVersionedTextContentSpan(span: ITextSpan): boolean {
    return (
        span.type === SpanType.Word ||
        span.type === SpanType.Space ||
        span.type === SpanType.Newline ||
        span.type === SpanType.Variable
    );
}

export function isNewlineLikeCharacter(ch: number): boolean {
    return (
        ch === CharacterCode.LineFeed ||
        ch === CharacterCode.CarriageReturn ||
        ch === CharacterCode.LineSeparator ||
        ch === CharacterCode.ParagraphSeparator ||
        ch === CharacterCode.NextLine
    );
}

export function forEachSpan(
    text: IRenderedSpans,
    callback: (textNode: OneOfRenderedSpans, index: number) => void
): void {
    if (text.spans.length > 0) {
        let lastIndex = text.spans.length - 1;
        if (text.spans[lastIndex].type === SpanType.End) {
            lastIndex--;
        }
        for (let i = 0; i < text.spans.length; i++) {
            callback(text.spans[i], i);
        }
    }
}

export function getHorizontalFlexAlignment(horizontalAlignment: HorizontalAlignment): string {
    switch (horizontalAlignment) {
        case 'left':
            return 'flex-start';
        case 'center':
            return 'center';
        case 'right':
            return 'flex-end';
        case 'justify':
            return 'flex-start';
    }
}

export function getVerticalFlexAlignment(verticalAlignment: VerticalAlignment): string {
    switch (verticalAlignment) {
        case 'top':
            return 'flex-start';
        case 'middle':
            return 'center';
        case 'bottom':
            return 'flex-end';
    }
}

export function createRichTextFromString(str: string): IText {
    return {
        style: createDefaultTextProperties(),
        spans: createSpansFromString(str).concat([
            {
                type: SpanType.End,
                content: 'END',
                top: 0,
                left: 0,
                width: 0,
                height: 0,
                lineHeight: 0,
                attributes: {},
                dir: TextDirection.Ltr
            }
        ])
    };
}

export function createRichTextFromSegments(segments: TextSegmentDto[]): IText {
    const spans = createSpansFromSegments(segments);
    return {
        style: createDefaultTextProperties(),
        spans: spans.concat([
            {
                type: SpanType.End,
                content: 'END',
                top: 0,
                left: 0,
                width: 0,
                height: 0,
                lineHeight: 0,
                attributes: {},
                dir: TextDirection.Ltr
            }
        ])
    };
}

export function createRichTextVariableFromString(
    str: string,
    attributes: ISpanProperties = {},
    style?: Partial<ICharacterProperties>
): IText {
    return {
        style: createDefaultTextProperties(),
        spans: createSpansFromString(str, attributes, style).concat([
            {
                type: SpanType.End,
                content: 'END',
                top: 0,
                left: 0,
                width: 0,
                height: 0,
                lineHeight: 0,
                attributes: {},
                dir: TextDirection.Ltr
            }
        ])
    };
}

export function createSpansFromSegments(segments: TextSegmentDto[]): OneOfEditableSpans[] {
    const spans: OneOfEditableSpans[] = [];
    for (const segment of segments) {
        spans.push(...createSpansFromString(segment.content));
    }

    return spans;
}

export function createSpansFromString(
    str: string,
    properties: ISpanProperties = {},
    style?: Partial<ICharacterProperties>,
    copyStyleFn?: (style: Partial<ICharacterProperties>) => Partial<ICharacterProperties>,
    styleId?: string,
    styleIds?: IStyleIdMap,
    previousStyleIds?: (string | PreviousStyleIdType)[],
    previousStyleIdToHistoryIndexMap?: Map<string, number>
): OneOfEditableSpans[] {
    const spans: OneOfEditableSpans[] = [];
    let currentTextSpan: OneOfEditableSpans | undefined;
    let lastSpanType: SpanType | undefined;
    str = typeof str === 'number' ? (str as number).toString() : str;

    /**
     * Make sure variable artifacts are treated as a whole word
     * It's reverted after the spans has been assembled
     * Should only get in here when creating a new feeded element
     */
    if (style?.variable && str === VARIABLE_PREFIX + decodeFeedPath(style.variable.path)) {
        str = str.replace(/\s/g, '&nbsp;');
    }

    for (let i = 0; i < str.length; i++) {
        const ch = str.charCodeAt(i);
        if (isSpaceLikeCharacter(ch)) {
            if (lastSpanType === SpanType.Space) {
                (currentTextSpan as ISpan).content += String.fromCharCode(ch);
            } else {
                currentTextSpan = {
                    top: 0,
                    left: 0,
                    type: SpanType.Space,
                    content: String.fromCharCode(ch),
                    attributes: cloneDeep(properties.attributes || {}),
                    style: style ? copyStyleFn!(style) : {},
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    styleIds: styleIds || {},
                    styleId,
                    __previousStyleIds: [...(previousStyleIds || [])],
                    __previousStyleIdToHistoryIndexMap: new Map<string, number>(
                        previousStyleIdToHistoryIndexMap || []
                    )
                };
                spans.push(currentTextSpan);
                lastSpanType = SpanType.Space;
            }
        } else if (isNewlineLikeCharacter(ch)) {
            currentTextSpan = {
                top: 0,
                left: 0,
                type: SpanType.Newline,
                content: String.fromCharCode(ch),
                width: 0,
                height: 0,
                lineHeight: 0,
                style: style ? copyStyleFn!(style) : {},
                attributes: cloneDeep(properties.attributes || {}),
                styleIds: styleIds || {},
                styleId,
                __previousStyleIds: [...(previousStyleIds || [])],
                __previousStyleIdToHistoryIndexMap: new Map<string, number>(
                    previousStyleIdToHistoryIndexMap || []
                )
            };
            spans.push(currentTextSpan);
            lastSpanType = SpanType.Newline;
        } else {
            if (lastSpanType === SpanType.Word || lastSpanType === SpanType.Variable) {
                (currentTextSpan as ISpan).content += String.fromCharCode(ch);
            } else {
                if (style?.variable) {
                    let newStyle = {};

                    newStyle = copyStyleFn ? copyStyleFn(style) : style;

                    currentTextSpan = {
                        type: SpanType.Variable,
                        content: String.fromCharCode(ch),
                        style: newStyle,
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        lineHeight: 0,
                        attributes: cloneDeep(properties.attributes || {}),
                        styleIds: styleIds || {},
                        styleId,
                        __previousStyleIds: [...(previousStyleIds || [])],
                        __previousStyleIdToHistoryIndexMap: new Map<string, number>(
                            previousStyleIdToHistoryIndexMap || []
                        )
                    };
                } else {
                    currentTextSpan = createWordSpanFromCharacter(
                        ch,
                        style ?? {},
                        properties.attributes,
                        {
                            styleId,
                            styleIds,
                            previousStyleIds,
                            previousStyleIdToHistoryIndexMap
                        }
                    );
                }
                spans.push(currentTextSpan);
                lastSpanType = style?.variable ? SpanType.Variable : SpanType.Word;
            }
        }
    }

    for (const span of spans) {
        if (isVariableSpan(span)) {
            span.content = span.content.replace(/&nbsp;/gi, ' ');
        }
        span.attributes.shouldRenderNumber = false;
    }

    return spans;
}

export function escapeSpanContent(content: string): string {
    return (
        content
            .replace(/\n/g, '\\n')
            .replace(/"/g, '\\"')
            .replace(/'/g, `\\'`)
            .replace(/&/g, '\\&')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
            .replace(/\f/g, '\\f')
            .replace(/(\\)(?!n|"|'|&|r|t|f|u\d)/g, '\\\\') // Escape backslashes, if it's not unicode/metachar

            // Legacy: Some documents have newline characters that are not supported. Old Chrome browser cannot handle raw newline characters.
            // They will be converted next time they edit the text. But for now data.js is invalid with these characters.
            .replace(/\u2028/g, '\\u2028') // Line Separator
            .replace(/\u2029/g, '\\u2029') // Paragraph Separator
            .replace(/\u0085/g, '\\u0085')
    ); // Next Line
}

export function unescapeSpanContent(content: string): string {
    return content
        .replace(/\\n/g, '\n')
        .replace(/\\"/g, '"')
        .replace(/\\'/g, "'")
        .replace(/\\&/g, '&')
        .replace(/\\r/g, '\r')
        .replace(/\\t/g, '\t')
        .replace(/\\f/g, '\f')
        .replace(/\\\\/g, '\\')
        .replace(/\\u2028/g, '\u2028') // Line Separator
        .replace(/\\u2029/g, '\u2029') // Paragraph Separator
        .replace(/\\u0085/g, '\u0085'); // Next Line
}

export function remapStyles(
    text: IVersionedText,
    oldDocumentId: string,
    newDocumentId: string
): IVersionedText {
    if (newDocumentId === oldDocumentId) {
        return text;
    }
    for (const span of text.styles) {
        const oldStyleIds = { ...span.styleIds };
        for (const [documentId, styleId] of Object.entries(oldStyleIds)) {
            if (documentId === oldDocumentId) {
                span.styleIds[newDocumentId] = styleId;
                delete span.styleIds[oldDocumentId];
            }
        }
    }
    return text;
}

export function updateStyleIds(
    property: IVersionProperty<IVersionedText>,
    styleIdMap: Map<string, string>,
    newDocumentId: string
): void {
    for (const [oldId, newId] of styleIdMap) {
        for (const style of property.value.styles) {
            for (const oldDocumentId in style.styleIds) {
                if (style.styleIds[oldDocumentId] === oldId) {
                    style.styleIds[newDocumentId] = newId;
                    delete style.styleIds[oldDocumentId];
                }
            }
        }
    }
}

export function isVersionedWidgetText(
    property: IVersionProperty | IWidgetElementProperty | IWidgetCustomProperty | undefined
): property is IVersionProperty<IWidgetText> {
    const propertyValue = property?.value;
    if (!propertyValue) {
        return false;
    }

    if (typeof propertyValue === 'string') {
        try {
            const parsed = JSON.parse(propertyValue);
            return parsed.text !== undefined && parsed.styles === undefined;
        } catch (e) {
            return false;
        }
    }
    try {
        return (
            typeof propertyValue === 'object' &&
            'text' in propertyValue &&
            !('styles' in propertyValue) &&
            propertyValue.text !== undefined
        );
    } catch (e) {
        return false;
    }
}

export function isVersionedText(
    property: IVersionProperty | IWidgetElementProperty | IWidgetCustomProperty | undefined
): property is IVersionProperty<IVersionedText> {
    const propertyValue = property?.value;
    if (!propertyValue) {
        return false;
    }

    if (typeof propertyValue === 'string') {
        try {
            const parsed = JSON.parse(propertyValue) as IVersionedText;
            return parsed.text !== undefined && parsed.styles !== undefined;
        } catch (e) {
            return false;
        }
    }
    try {
        return (
            typeof propertyValue === 'object' &&
            'text' in propertyValue &&
            'styles' in propertyValue &&
            propertyValue.text !== undefined &&
            propertyValue.styles !== undefined
        );
    } catch (e) {
        return false;
    }
}

/**
 * **NOTE**
 *
 * This function will mutate the provided data node in order to properly re-create character styles
 */
export function cloneNodeStyles(node: OneOfTextDataNodes, documentId: string): Map<string, string> {
    node.__styleHashMap.clear();

    /** `Map<old style id, new style id>` */
    const idMap = new Map<string, string>();

    for (const [styleId, characterStyle] of [...node.characterStyles]) {
        node.characterStyles.delete(styleId);
        const newStyleId = uuidv4();
        idMap.set(styleId, newStyleId);
        for (const span of node.content.spans) {
            if (isContentSpan(span)) {
                if (span.styleId === styleId) {
                    span.styleId = newStyleId;
                }

                if (span.styleIds[documentId] === styleId) {
                    span.styleIds[documentId] = newStyleId;
                }
            }
        }

        node.__styleHashMap.set(getHashFromStyle(characterStyle), newStyleId);
        node.characterStyles.set(newStyleId, characterStyle);
    }

    return idMap;
}

export function hasSameStyleIds(source: IStyleIdMap, target: IStyleIdMap): boolean {
    if (Object.keys(source).length !== Object.keys(target).length) {
        return false;
    }
    for (const [sourceDesignId, sourceStyleId] of Object.entries(source)) {
        if (!(target[sourceDesignId] && target[sourceDesignId] === sourceStyleId)) {
            return false;
        }
    }
    return true;
}

export function hasSamePreviousStyleIds(
    source: IContentSpan['__previousStyleIds'],
    target: IContentSpan['__previousStyleIds']
): boolean {
    // undefined or empty arrays are the same -- Problem from TP
    if (!source?.length && !target?.length) {
        return true;
    }
    return deepEqual(source, target);
}

export function copyVersionPropertyValue(property: IVersionProperty): OneOfVersionableProperties {
    const value = property.value;

    if (isVersionedWidgetText(property)) {
        return cloneDeep(property.value);
    }

    if (isVersionedText(property)) {
        return deserializeVersionedText(serializeVersionedText(property.value));
    }

    return deserializeVersionPropertyValue(
        property.name as OneOfElementPropertyKeys,
        serializeVersionPropertyValue(property.name, value)
    ) as OneOfVersionableProperties;
}

/**
 * Get raw string from IText (without formatting)
 * @param text
 * @param tw
 */
export function getTextContent(text: ISpans): string {
    let str = '';
    outer: for (const span of text.spans) {
        switch (span.type) {
            case SpanType.Newline:
                str += '\n';
                break;
            case SpanType.Space:
            case SpanType.Word:
                str += span.content;
                break;
            case SpanType.Variable:
                str += span.content;
                break;
            case SpanType.End:
                break outer;
            default:
                throw new Error('Unknown span type.');
        }
    }
    return str;
}

export function isSubStyleOf(
    source: Partial<ICharacterProperties>,
    target: Partial<ICharacterProperties>
): boolean {
    const sourceStyle = omit(source, 'variable');
    const targetStyle = omit(target, 'variable');
    if (Object.keys(sourceStyle).length < Object.keys(targetStyle).length) {
        return false;
    }
    for (const property in targetStyle) {
        if (!hasSameStyleProperty(property as CharacterPropertyKeys, targetStyle, sourceStyle)) {
            return false;
        }
    }
    return true;
}

export function hasSameStyle<Properties extends TextElementAndCharacterStyleProperties>(
    source: Partial<Properties>,
    target: Partial<Properties>
): boolean {
    const sourceStyle = omit(source, 'variable', '__fontFamilyId');
    const targetStyle = omit(target, 'variable', '__fontFamilyId');
    if (Object.keys(sourceStyle).length !== Object.keys(targetStyle).length) {
        return false;
    }
    for (const property in sourceStyle) {
        if (
            !hasSameStyleProperty(
                property as keyof TextElementAndCharacterStyleProperties,
                sourceStyle,
                targetStyle
            )
        ) {
            return false;
        }
    }
    return true;
}

export function hasSameStyleProperty<Properties extends TextElementAndCharacterStyleProperties>(
    property: keyof Properties,
    source: Partial<Properties>,
    target: Partial<Properties>,
    treatMissingAsFalse = true
): boolean {
    const targetValue = target[property];
    if (targetValue === '$mixed') {
        return false;
    }
    const sourceValue = source[property];
    switch (property) {
        case 'uppercase':
        case 'underline':
        case 'strikethrough':
            // We need to distinguish between 'missing' and 'false' values sometimes. An example, is when we want to promote commonly
            // styled properties across whole text to element styles. A 'uppercase: false' is not the same as 'uppercase: undefined'.
            // If we have in the first span 'uppercase: false' and all the rest of the spans has 'uppercase: undefined'. We don't want
            // to promote 'uppercase: false' to element style.
            if (!treatMissingAsFalse) {
                return sourceValue === targetValue;
            }
            return !!sourceValue === !!targetValue;

        case 'font': {
            const targetStyle = targetValue as IFontStyle;
            const sourceStyle = sourceValue as IFontStyle;
            if (sourceStyle === targetStyle) {
                return true;
            }
            if (targetStyle === undefined || sourceStyle === undefined) {
                return false;
            }
            return (
                targetStyle.id === sourceStyle.id &&
                targetStyle.fontFamilyId === sourceStyle.fontFamilyId &&
                targetStyle.style === sourceStyle.style &&
                targetStyle.weight === sourceStyle.weight &&
                targetStyle.src === sourceStyle.src
            );
        }
        case 'textColor':
            if (sourceValue === targetValue) {
                return true;
            }
            if (sourceValue === undefined || targetValue === undefined) {
                return false;
            }
            if (!(sourceValue instanceof Color && targetValue instanceof Color)) {
                throw new Error(`Neither 'targetValue' nor 'sourceValue' is of type Color.`);
            }
            return sourceValue.toString() === targetValue.toString();
        case 'padding': {
            const targetPadding = targetValue as IPadding;
            const sourcePadding = sourceValue as IPadding;
            if (sourcePadding === targetPadding) {
                return true;
            }
            if (targetPadding === undefined || sourcePadding === undefined) {
                return false;
            }
            return (
                targetPadding.top === sourcePadding.top &&
                targetPadding.bottom === sourcePadding.bottom &&
                targetPadding.left === sourcePadding.left &&
                targetPadding.right === sourcePadding.right
            );
        }
        case 'textShadows': {
            const sourceShadows = sourceValue as IShadow[];
            const targetShadows = targetValue as IShadow[];
            if (typeof targetShadows !== typeof sourceShadows) {
                return false;
            }
            if (targetShadows === sourceShadows) {
                return true;
            }
            if (targetShadows.length !== sourceShadows.length) {
                return false;
            }
            for (let i = 0; i < sourceShadows.length; i++) {
                const sourceShadow = sourceShadows[i];
                const targetShadow = targetShadows[i];
                const isSame =
                    sourceShadow.blur === targetShadow.blur &&
                    sourceShadow.color === targetShadow.color &&
                    sourceShadow.offsetX === targetShadow.offsetX &&
                    sourceShadow.offsetY === targetShadow.offsetY &&
                    sourceShadow.spread === targetShadow.spread;

                if (!isSame) {
                    return false;
                }
            }
            return true;
        }
        default:
            return sourceValue === targetValue;
    }
}

export function getMaxIndexFromStyleMap(styleIndexMap: Map<string, number>): number {
    let maxIndex = 0;
    for (const index of styleIndexMap.values()) {
        if (index > maxIndex) {
            maxIndex = index;
        }
    }
    return maxIndex;
}

export function createStyleIndexMap(
    text: IVersionedText,
    existingStyleIndexMap?: IStyleIndexMaps
): IStyleIndexMaps {
    const styleHashToIndexMap = existingStyleIndexMap?.styleHashToIndexMap || new Map<string, number>();
    const indexToStyleIdsMap =
        existingStyleIndexMap?.indexToStyleIdsMap || new Map<number, IStyleIdMap>();
    let currentStyleIndex = existingStyleIndexMap
        ? getMaxIndexFromStyleMap(existingStyleIndexMap.styleHashToIndexMap)
        : 0;

    for (let i = 0; i < text.styles.length; i++) {
        const span = text.styles[i];
        switch (span.type) {
            case SpanType.Word:
            case SpanType.Space:
            case SpanType.Variable:
                if (Object.keys(span.styleIds).length === 0) {
                    continue;
                }
                let styleIndex: number | undefined;
                const sequencedStyleIds = sequenceStyleIds(span.styleIds);
                styleIndex = styleHashToIndexMap.get(sequencedStyleIds);
                if (styleIndex === undefined) {
                    currentStyleIndex++;
                    styleIndex = currentStyleIndex;
                }
                indexToStyleIdsMap.set(styleIndex, span.styleIds);
                styleHashToIndexMap.set(sequencedStyleIds, styleIndex);
                continue;
        }
    }

    return { styleHashToIndexMap, indexToStyleIdsMap };
}

export function sequenceStyleIds(styleIds: IStyleIdMap): string {
    let serializedStyle = '';
    const sortedStyleIds = Array.from(Object.values(styleIds)).sort();
    for (const styleId of sortedStyleIds) {
        serializedStyle += styleId;
    }
    return serializedStyle;
}

export function getHashFromStyle(style: Partial<ICharacterProperties>): string {
    // Filter varaible from ICharacterProperties, it's saved in VersionProperty
    const filteredStyle = omit(style, 'variable');
    return CRC32checksum(sequenceStyle(filteredStyle));
}

export function isEmptyStyle(style: Partial<ICharacterProperties>): boolean {
    return Object.keys(omit(style, '__fontFamilyId', 'variable')).length === 0;
}

export function getCommonStyledPropertiesFromUniqueOnes(
    uniquelyStyledProperties: Set<string>,
    style: Partial<ICharacterProperties>
): Set<string> {
    return new Set(Object.keys(style).filter(property => !uniquelyStyledProperties.has(property)));
}

export function sequenceStyle(style: Partial<ICharacterProperties>): string {
    let styleSequencedString = '';
    for (const property of textProperties) {
        if (!style) {
            continue;
        }

        const styleValue = style[property];
        if (styleValue === undefined) {
            continue;
        }

        if (property === 'font') {
            styleSequencedString +=
                property +
                (typeof styleValue === 'string' ? styleValue : (styleValue as IFontStyle).id);
        } else if (styleValue !== undefined) {
            styleSequencedString +=
                property +
                JSON.stringify(convertCharacterStyleToDto({ [property]: styleValue })[property]);
        }
    }

    return styleSequencedString;
}

export function copySpans(spans: OneOfEditableSpans[]): OneOfEditableSpans[] {
    const result: OneOfEditableSpans[] = [];
    for (const span of spans) {
        result.push(copySpan(span));
    }
    return result;
}

export function copySpan<T extends OneOfEditableSpans>(span: T): T {
    const copy = cloneDeep(span);
    if (isContentSpan(span)) {
        const contentSpanCopy = copy as IContentSpan;
        contentSpanCopy.styleId = span.styleId;
        contentSpanCopy.style = cloneDeep(span.style);
        contentSpanCopy.styleIds = { ...(span.styleIds || {}) };
        if (span.__previousStyleIdToHistoryIndexMap) {
            contentSpanCopy.__previousStyleIdToHistoryIndexMap = new Map([
                ...span.__previousStyleIdToHistoryIndexMap
            ]);
        }
        contentSpanCopy.__previousStyleIds = cloneDeep(span.__previousStyleIds);
    }
    return copy;
}

export function copyStyle<T extends Partial<ICharacterProperties & ITextElementProperties>>(
    style: T
): T {
    const styleCopy = {} as T;
    for (const property in style) {
        if (style[property] === undefined) {
            continue;
        }
        switch (property) {
            case 'textColor': {
                const prop = style[property];
                if (prop instanceof Color) {
                    styleCopy.textColor = prop.copy();
                }
                break;
            }
            case 'fontSize':
                styleCopy.fontSize = style.fontSize;
                break;
            default:
                styleCopy[property] = cloneDeep(style[property]);
                break;
        }
    }
    return styleCopy;
}

export function resolveElementTextStyle<T extends OneOfViewNodes>(element: T): T {
    if (isTextNode(element)) {
        const dataNode = element.__data;
        const text = element.__richTextRenderer?.editor_m!.styleResolver.getResolvedText();
        if (!text) {
            return element;
        }
        for (const property in text.style) {
            if (!textProperties.includes(property as keyof ITextElementProperties)) {
                continue;
            }
            element[property] = element.__data[property] = text.style[property];
        }
        if (dataNode.__dirtyContent) {
            dataNode.__dirtyContent.spans = text.spans;
        }
    }
    return element;
}

const NINETY_DEGREES = Math.PI / 2;
const ONE_EIGHTY_DEGREES = Math.PI;
const TWO_SEVENTY_DEGREES = 1.5 * Math.PI;

export function calculateTop(
    textLineElement: HTMLElement,
    boundingRect: DOMRect,
    rotationZ: number
): number {
    const rotation = -rotationZ || 0;
    const modRotation = mod(rotation, 2 * Math.PI);
    const lineElementBoundingRect = textLineElement.getBoundingClientRect();

    // first quadrant
    if (modRotation < NINETY_DEGREES) {
        return (lineElementBoundingRect.top - boundingRect.top) / Math.cos(modRotation);
    }
    // ninety degrees
    else if (modRotation === NINETY_DEGREES) {
        return lineElementBoundingRect.left - boundingRect.left;
    }
    // second quadrant
    else if (modRotation < ONE_EIGHTY_DEGREES) {
        return (boundingRect.bottom - lineElementBoundingRect.bottom) / Math.cos(Math.PI - modRotation);
    }
    // one eighty degrees
    else if (modRotation === ONE_EIGHTY_DEGREES) {
        return boundingRect.bottom - lineElementBoundingRect.bottom;
    }
    // third quadrant
    else if (modRotation < TWO_SEVENTY_DEGREES) {
        return (boundingRect.bottom - lineElementBoundingRect.bottom) / Math.cos(modRotation - Math.PI);
    }
    // two seventy degrees
    else if (modRotation === TWO_SEVENTY_DEGREES) {
        return boundingRect.right - lineElementBoundingRect.right;
    }
    // fourth quadrant
    else {
        return (
            (boundingRect.right - lineElementBoundingRect.right) / Math.cos(modRotation - 1.5 * Math.PI)
        );
    }
}
