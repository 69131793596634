import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep } from '@apollo/client/utilities';
import { GenAIService } from '@app/shared/ai-studio/state/gen-ai.service';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    designWidgetElementCodePropertyRegex,
    hasWidgetContentBlobReference
} from '@creative/elements/widget/utils';
import { isImageNode, isVideoNode, isWidgetNode } from '@creative/nodes/helpers';
import { IBrandLibraryWidgetElement } from '@domain/brand/brand-library';
import { IDesign, IElement } from '@domain/creativeset';
import {
    AssetReference,
    IImageElementAsset,
    IVideoElementAsset
} from '@domain/creativeset/element-asset';
import { IFeed } from '@domain/feed';
import {
    IElementDataNode,
    IImageElementDataNode,
    IVideoElementDataNode,
    OneOfElementDataNodes
} from '@domain/nodes';
import { SaveType } from '@studio/domain/components/ai-studio.types';
import {
    EventLoggerService,
    ImagePropertyChangeEvent,
    VideoPropertyChangeEvent
} from '@studio/monitoring/events';
import { distinctArrayById } from '@studio/utils/array';
import { getAssetReferenceTypeOfElement, getFeedReferenceTypeOfElement } from '@studio/utils/asset';
import {
    createElement,
    createElementProperty,
    getWidgetContentUrlOfElement
} from '@studio/utils/element.utils';
import { filter } from 'rxjs';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { DesignViewComponent } from '../design-view.component';
import { BrandLibraryElementService } from '../media-library';
import { EditorStateService } from './editor-state.service';
import { ElementSelectionService } from './element-selection.service';
import { MutatorService } from './mutator.service';

@Injectable()
export class ElementReplaceService {
    private editorStateService = inject(EditorStateService);
    private brandLibraryElementService = inject(BrandLibraryElementService);
    private editor = inject(DesignViewComponent);
    private elementSelectionService = inject(ElementSelectionService);
    private brandLibraryDataService = inject(BrandLibraryDataService);
    private eventLoggerService = inject(EventLoggerService);
    private mutatorService = inject(MutatorService);
    private versionsService = inject(VersionsService);
    private genAIService = inject(GenAIService);

    private designs: IDesign[] = [];

    private logger = new Logger('ElementReplaceService');

    constructor() {
        this.designs = distinctArrayById([
            this.editorStateService.designFork,
            ...this.editorStateService.designs
        ]);

        this.genAIService.saveOnCanvasPayload$
            .pipe(
                filter(payload => payload?.saveType === SaveType.Replace),
                takeUntilDestroyed()
            )
            .subscribe(payload => {
                if (!payload) return;

                this.replaceImage(payload.imageAsset, payload.replaceInAllDesigns);
            });
    }

    replaceImage(imageAsset: IImageElementAsset, replaceInAllDesigns = false): void {
        const imageElements =
            this.elementSelectionService.currentSelection.elements.filter(isImageNode);
        for (const element of imageElements) {
            this.eventLoggerService.log(
                new ImagePropertyChangeEvent('imageAsset', element.imageAsset?.url, imageAsset.url),
                this.logger
            );

            this.mutatorService.setElementPropertyValue(element, 'imageAsset', imageAsset);

            // replace in design
            if (replaceInAllDesigns) {
                this.replaceImageInAllDesigns(element, imageAsset);
            } else {
                this.replaceImageInDesign(element, imageAsset, this.editorStateService.designFork);
            }

            this.replaceElementWithMediaReference(element, imageAsset.id);
        }
    }

    replaceVideo(videoAsset: IVideoElementAsset, replaceInAllDesigns = false): void {
        const videoElements =
            this.elementSelectionService.currentSelection.elements.filter(isVideoNode);
        for (const element of videoElements) {
            this.eventLoggerService.log(
                new VideoPropertyChangeEvent('videoAsset', element.videoAsset?.url, videoAsset.url),
                this.logger
            );

            this.mutatorService.setElementPropertyValue(element, 'videoAsset', videoAsset);

            if (replaceInAllDesigns) {
                this.replaceVideoInAllDesigns(element, videoAsset);
            } else {
                this.replaceVideoInDesign(element, videoAsset, this.editorStateService.designFork);
            }

            this.replaceElementWithMediaReference(element, videoAsset.id);
        }
    }

    replaceImageInAllDesigns(element: IImageElementDataNode, imageAsset: IImageElementAsset): void {
        this.designs.forEach(design => {
            this.replaceImageInDesign(element, imageAsset, design);
        });
    }

    replaceImageInDesign(
        element: IImageElementDataNode,
        imageAsset: IImageElementAsset,
        designFork: IDesign
    ): void {
        const newImageAsset: IImageElementAsset = {
            id: imageAsset.id,
            url: imageAsset.url,
            name: imageAsset.name,
            width: imageAsset.width,
            height: imageAsset.height,
            isGenAi: imageAsset.isGenAi
        };

        const elementInDesign = designFork.elements.find(e => e.id === element.id);
        if (elementInDesign) {
            // holding the image reference in the shared element properties list
            // is only used for BE to knwo what images being used in the creative set
            const property = elementInDesign.properties.find(e => e.name === AssetReference.Image);
            if (property) {
                property.value = newImageAsset.id;
            }

            const libraryElement = this.brandLibraryDataService.getElementByAssetId(
                AssetReference.Image,
                imageAsset.id
            );

            const documentElement = designFork.document.elements.find(
                e => e.id === elementInDesign.id
            ) as IImageElementDataNode;

            documentElement.feed = undefined;
            documentElement.imageAsset = newImageAsset;
            documentElement.imageSettings = cloneDeep(element.imageSettings); // also set new settings when switching to svg
            documentElement.parentId = libraryElement?.id;
        }
    }

    replaceVideoInAllDesigns(element: IVideoElementDataNode, videoAsset: IVideoElementAsset): void {
        this.designs.forEach(design => {
            this.replaceVideoInDesign(element, videoAsset, design);
        });
    }

    replaceVideoInDesign(
        element: IVideoElementDataNode,
        videoAsset: IVideoElementAsset,
        designFork: IDesign
    ): void {
        const newVideoAsset: IVideoElementAsset = {
            id: videoAsset.id,
            url: videoAsset.url,
            width: videoAsset.width,
            height: videoAsset.height,
            name: videoAsset.name,
            fileSize: videoAsset.fileSize
        };

        const elementInDesign = designFork.elements.find(e => e.id === element.id);
        if (elementInDesign) {
            const property = elementInDesign.properties.find(e => e.name === AssetReference.Video);
            if (property) {
                property.value = newVideoAsset.id;
            }

            const libraryElement = this.brandLibraryDataService.getElementByAssetId(
                AssetReference.Video,
                newVideoAsset.id
            );

            const documentElements = designFork.document.elements;
            const videoElement = documentElements.find(
                e => e.id === elementInDesign.id
            ) as IVideoElementDataNode;

            videoElement.feed = undefined;
            videoElement.videoAsset = newVideoAsset;
            videoElement.videoSettings = cloneDeep(element.videoSettings); // also set new settings when switching to svg
            videoElement.parentId = libraryElement?.id;
        }
    }

    replaceElementWithMediaReference(element: OneOfElementDataNodes, assetId: string): void {
        const editorElement = this.editorStateService.getElementById(element.id);

        const assetReference = getAssetReferenceTypeOfElement(editorElement);
        const feededReferenceName = getFeedReferenceTypeOfElement(editorElement);

        const currentAssetReference = editorElement.properties.find(
            prop => prop.name === assetReference
        );

        const feededReference = editorElement.properties.find(
            prop => prop.name === feededReferenceName
        );

        const brandElement = this.brandLibraryDataService.getElementByAssetId(assetReference, assetId);

        const referenceProperty = createElementProperty({
            ...currentAssetReference,
            name: assetReference,
            unit: 'id',
            value: assetId
        });

        if (brandElement) {
            const newElement = createElement({
                id: editorElement.id,
                name: editorElement.name,
                type: editorElement.type,
                properties: []
            });

            if (feededReference) {
                newElement.properties.push(feededReference);
            } else {
                newElement.properties.push(referenceProperty);
            }

            const newElements = this.editorStateService.elements.filter(
                globalElement => globalElement.id !== editorElement.id
            );

            this.editorStateService.updateElements([...newElements, newElement]);
            return;
        }

        if (currentAssetReference) {
            currentAssetReference.value = assetId;
        } else {
            editorElement.properties.push(referenceProperty);
        }

        if (feededReference) {
            editorElement.properties = editorElement.properties.filter(
                prop => prop.name !== feededReferenceName
            );
        }
    }

    replaceFeededMediaInAllDesigns(element: IElementDataNode, feed: IFeed): void {
        this.designs.forEach(design => {
            this.replaceFeededMediaInDesign(element, feed, design);
        });
    }

    replaceFeededMediaInDesign(element: IElementDataNode, feed: IFeed, designFork: IDesign): void {
        const elementInDesign = designFork.elements.find(e => e.id === element.id);

        if (!elementInDesign) {
            return;
        }

        const documentElement = designFork.document.elements.find(e => e.id === elementInDesign.id)!;
        documentElement.feed = cloneDeep(feed);
        documentElement.parentId = undefined;

        if (isVideoNode(documentElement)) {
            documentElement.videoAsset = undefined;
        }

        if (isImageNode(documentElement)) {
            documentElement.imageAsset = undefined;
        }
    }

    async updateWidgetInAllDesigns(widgetLibraryElement: IBrandLibraryWidgetElement): Promise<void> {
        const designs = this.designs;

        const dataElements = this.editorStateService.document.elements;
        const designDocuments = designs.map(({ document }) => document);
        const globalDataElements = designDocuments.flatMap(({ elements }) => elements);
        // Get parentId on document elements since it's more reliable
        const widgetDataElements = [...dataElements, ...globalDataElements].filter(node =>
            this.brandLibraryDataService.isParentElementOfNode(widgetLibraryElement, node)
        );

        const editorElements = this.editorStateService.elements;
        const globalElements = designs.flatMap(({ elements }) => elements);
        const allElements = [...editorElements, ...globalElements];
        const elements = allElements.filter(
            element => !!widgetDataElements.find(({ id }) => id === element.id)
        );

        for (const element of elements) {
            // Update code properties & content blob for each element
            const codeProperties = widgetLibraryElement.properties.filter(prop =>
                designWidgetElementCodePropertyRegex.test(prop.name)
            );
            codeProperties.forEach(prop => {
                const elementProperty = element.properties.find(({ name }) => name === prop.name);
                if (elementProperty) {
                    elementProperty.value = prop.value;
                } else {
                    element.properties.push(
                        createElementProperty({
                            unit: prop.unit,
                            label: prop.label,
                            value: prop.value,
                            name: prop.name
                        })
                    );
                }
            });

            const newContentUrl = getWidgetContentUrlOfElement(widgetLibraryElement);
            const blobReference = element.properties.find(hasWidgetContentBlobReference);

            if (blobReference && newContentUrl) {
                blobReference.value = newContentUrl;
            }

            const allWidgetElements = widgetDataElements.filter(({ id }) => id === element.id);

            // Update custom properties for all document elements with the element reference
            for (const elementInDesign of allWidgetElements) {
                if (!isWidgetNode(elementInDesign)) {
                    return;
                }

                await this.brandLibraryElementService.updateWidgetFromLibrary(
                    elementInDesign,
                    widgetLibraryElement,
                    element
                );
            }
        }

        /**
         * Reflect changes made to current elements on the global elements.
         * This occurs after doing the actual updating in order to prevent properties
         * to be created when they shouldn't
         */
        for (const element of elements) {
            for (const globalElement of globalElements) {
                if (element.id === globalElement.id) {
                    globalElement.properties = cloneDeep(element.properties);
                }
            }
        }

        this.cleanOrphanVersionProperties(allElements);

        const selectedNodes = this.elementSelectionService.currentSelection.nodes;

        this.editor.rerenderCanvas();

        if (selectedNodes.length > 0) {
            this.elementSelectionService.setSelection(...selectedNodes);
            this.editor.workspace.redrawGizmos();
        }
    }

    private cleanOrphanVersionProperties(elements: IElement[]): void {
        const allElements = distinctArrayById(elements);
        const versionPropertyReferences = allElements
            .flatMap(({ properties }) => properties.map(({ versionPropertyId }) => versionPropertyId))
            .filter(Boolean) as string[];

        const orphanVersionProperties = this.editorStateService.versionProperties
            .filter(versionProperty => !versionPropertyReferences.some(id => id === versionProperty.id))
            .map(vp => vp.id);

        this.versionsService.removeVersionPropertiesByIds(orphanVersionProperties);
    }
}
