import { Injectable, inject } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { serializeVersionProperties } from '@creative/serialization/versions/version-serializer';
import { convertCreativesetToDto, deserializeCreative } from '@data/deserialization/creativeset';
import { deserializeVersion, deserializeVersions } from '@data/deserialization/version';
import {
    CREATE_VERSION_MUTATION,
    DELETE_VERSIONS_MUTATION,
    SET_DEFAULT_VERSION_MUTATION,
    UPDATE_VERSIONS_MUTATION
} from '@data/graphql/versions.queries';
import { CreativeUpdate, ICreative } from '@domain/creativeset/creative/creative';
import { ICreateVersionsVariables, IVersion } from '@domain/creativeset/version';
import { EventLoggerService, VersionValidationFailEvent } from '@studio/monitoring/events';
import { cloneDeep } from '@studio/utils/clone';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';
import { fixWidgetVersionProperties } from '../utils/widget-version-properties';
import { validateVersions } from './versions.validation';

@Injectable({
    providedIn: 'root'
})
export class VersionsDataService {
    private apollo = inject(Apollo);
    private creativesetDataService = inject(CreativesetDataService);
    private eventLoggerService = inject(EventLoggerService);
    private sentinelService = inject(SentinelService);

    createVersions(
        creativesetId: string,
        newVersions: IVersion[]
    ): Observable<{ versions: IVersion[]; creatives: ICreative[] }> {
        try {
            validateVersions(newVersions);
        } catch (e) {
            if (e instanceof VersionValidationFailEvent) {
                this.eventLoggerService.log(e);
            }
        }
        newVersions = fixWidgetVersionProperties(
            newVersions,
            this.creativesetDataService.creativeset.creatives
        );
        const versionsToUpdate: ICreateVersionsVariables['versions'] = newVersions.map(version => ({
            name: version.name,
            localizationId: version.localization.id,
            targetUrl: version.targetUrl,
            properties: serializeVersionProperties(version.properties, this.sentinelService)
        }));

        const variables: ICreateVersionsVariables = {
            versions: versionsToUpdate,
            creativesetId: creativesetId
        };

        return this.apollo
            .mutate({
                mutation: CREATE_VERSION_MUTATION,
                variables
            })
            .pipe(
                map(result => {
                    if (!result.data?.createVersions) {
                        throw new Error('Could not deserialize CreateVersion response.');
                    }

                    const { versions, creatives } = result.data.createVersions;
                    const creativeset = this.creativesetDataService.creativeset;
                    const creativesetDto = convertCreativesetToDto(creativeset, this.sentinelService);

                    creativesetDto.versions.push(...versions);

                    const deserializedCreatives = creatives.map(creative =>
                        deserializeCreative(creativesetDto, creative, creativeset.designs)
                    );

                    return {
                        versions: deserializeVersions(versions),
                        creatives: cloneDeep(deserializedCreatives)
                    };
                })
            );
    }

    updateVersions(
        creativesetId: string,
        versions: IVersion[],
        dirtyCreatives: CreativeUpdate[]
    ): Observable<IVersion[]> {
        try {
            validateVersions(versions);
        } catch (e) {
            if (e instanceof VersionValidationFailEvent) {
                this.eventLoggerService.log(e);
            }
        }
        const newVersions = fixWidgetVersionProperties(
            versions,
            this.creativesetDataService.creativeset.creatives
        );
        const variables = {
            versions: newVersions.map(version => ({
                id: version.id,
                name: version.name,
                localizationId: version.localization.id,
                targetUrl: version.targetUrl,
                properties: serializeVersionProperties(version.properties, this.sentinelService)
            })),
            creativesetId,
            creatives: dirtyCreatives
        };

        return this.apollo
            .mutate({
                mutation: UPDATE_VERSIONS_MUTATION,
                variables
            })
            .pipe(
                map(result => {
                    return deserializeVersions(result.data?.updateVersions.versions || []);
                })
            );
    }

    deleteVersions(creativesetId: string, versions: IVersion[]): Observable<{ ids: string[] }> {
        const versionIds = versions.map(({ id }) => id);

        return this.apollo
            .mutate({
                mutation: DELETE_VERSIONS_MUTATION,
                variables: {
                    ids: versionIds,
                    creativesetId: creativesetId
                }
            })
            .pipe(
                map(result => {
                    if (!result.data?.deleteVersions) {
                        throw new Error('Could not deserialize DeleteVersions response.');
                    }
                    return result.data.deleteVersions;
                })
            );
    }

    setDefaultVersion(creativesetId: string, version: IVersion): Observable<IVersion> {
        const versionId = version.id;

        return this.apollo
            .mutate({
                mutation: SET_DEFAULT_VERSION_MUTATION,
                variables: {
                    creativesetId: creativesetId,
                    versionId
                }
            })
            .pipe(
                map(result => {
                    if (!result.data?.setDefaultVersion) {
                        throw new Error('Could not deserialize SetDefaultVersion response.');
                    }
                    return deserializeVersion(result.data.setDefaultVersion);
                })
            );
    }
}
